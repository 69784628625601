<template>
  <div class="main-conent main-conent-minheight">
    <!-- 全屏 -->
    <div class="all" v-show="isall">
      <allhed :title="'订单生产看板'" :boxSize="boxSize"></allhed>

      <div class="allbox" v-for="(arr,index) in list" :key="index">
        <div class="allboxhed">
          <span class="section">订单编号:{{ arr.no }}</span>
          <span class="section" :class="'ord'+arr.status">订单名称:{{ arr.name }}</span>
          <span style=" background-color: #35b72a;" class="onlin">
            <!-- <i class="el-icon-circle-check"></i> -->
            正常{{ arr.zc }}
          </span>
          <span style=" background-color: #999CA5;">
            <!-- <i class="el-icon-circle-close"></i> -->
            未开工{{ arr.wkg }}
          </span>
          <span style=" background-color: #e3460a;">
            <!-- <i class="el-icon-setting"></i> -->
            逾期{{ arr.yq }}
          </span>
        </div>
        <div class="boxmain">
          <div class="facilityBox" :class="'ord'+arr.status" v-for="(item,index) in arr.items" :key="index" style="    height: auto;    padding-bottom: 10px;">
            <p>{{ item.name }}</p>
            <div class="jgbox" style="margin-bottom: 10px;">

              <div class="ljbox">零件编号：{{ item.no }}
              </div>
              <div class="ljbox">图纸型号：{{ item.drawing_model }}</div>
              <div class="ljbox">零件交期：{{ item.plan_end_time }}</div>

              <div class="ljbox">数量：{{ item.num }}</div>
              <div class="ljbox">材质：{{ item.material }}</div>
            </div>
            <div class="gxbox">

              <div style="line-height: 30px;">工序进度</div>
              <div class="gxhed">
                <span>工序</span>
                <span>完成数量</span>
                <span>进度</span>
              </div>

              <div class="gxmain">
                <el-carousel height="60px" direction="vertical" :autoplay="true" indicator-position="none">
                  <el-carousel-item v-for="(gx,index) in swzmd(item.process)" :key="index">
                    <div class="gx" v-for="gxdata in gx" :key="gxdata.id">
                      <span>{{gxdata.process_name}}</span>
                      <span>{{gxdata.complete_num ? gxdata.complete_num :0}}</span>

                      <span>{{gxdata.progress  * 100}}%</span>

                    </div>
                  </el-carousel-item>
                </el-carousel>

              </div>
            </div>
            <div class="jdbox">
              <el-progress :text-inside="true" :stroke-width="24" :percentage="Number(item.progress)" color='#06acfa' :show-text="true" :format="format" text-color='#333333'>

              </el-progress>
            </div>

            <div class="san"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getorder } from "@/service/statistics";

require("echarts/theme/macarons"); // echarts theme
import Cookies from 'js-cookie';
import allhed from "./components/allhed";

export default {
  name: "Home",
  components: {
    allhed
  },
  data () {
    return {
      boxSize: 0,
      Screen: false,
      count: 0,
      isall: true,
      username: Cookies.get('userNane'),
      logo: Cookies.get('logo'),
      date: new Date().valueOf(),
      chart: null,
      resizeHandler: null,
      radio1: "今日",
      datevalue1: "",
      list: []
    };
  },
  computed: {
    ...mapGetters(["sidebarOpened"])
  },

  methods: {

    swzmd (arr) {
      let newarr = [];
      let newobj = [];

      arr.forEach((item, index) => {
        newobj.push(item);

        if ((index + 1) % 3 === 0) {
          newarr.push(newobj);
          newobj = [];
        }
        if ((index + 1) === arr.length && (index + 1) % 3 !== 0) {
          newarr.push(newobj);
        }
      });
      console.log(newarr);
      return newarr;

    },
    format (percentage) {
      return '总进度' + percentage + '%';
    },
    all () {
      this.isall = true;
    },
    device (id) {
      console.log(id);
      this.$router.push("/one?id=" + id);
    },
    getPersonnelOverview () {
      getorder().then(res => {
        this.list = res.data.list;
        // let zong = 0;
        this.list.forEach(item => {
          let zc = 0;
          let wkg = 0;
          let yq = 0;
          item.items.forEach(items => {
            if (items.status === 1) {
              wkg++;
            }
            if (items.status === 2) {
              zc++;
            }
            if (items.status === 3) {
              yq++;
            }
          });
          item.zc = zc;
          item.wkg = wkg;
          item.yq = yq;

        });
        // this.count = zong;
      });
    }
  },
  created () {
    this.getPersonnelOverview();
  },
  mounted () {
    this.all();
  }
};
</script>

<style lang="scss" scoped>
.v-modal {
  z-index: 1 !important;
}
.el-progress-bar__innerText {
  color: #272323;
}
.all {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  overflow-y: scroll;
  position: fixed;

  z-index: 999;
  background-color: #292626;
  top: 0;
  left: 0;
}

.allhed {
  width: 100%;
  text-align: center;
  color: white;
  height: 100px;
  line-height: 100px;
  border-bottom: 3px solid white;
  padding: 0 30px;
  box-sizing: border-box;
  position: relative;
}

.username {
  font-size: 32px;
}

.offall {
  position: absolute;
  top: 110px;
  right: 0;
  height: 32px;
  font-size: 0;
  line-height: 32px;
}

.allhed img {
  width: 40px;
  height: 40px;
  margin: 0px 10px;
  transform: translateY(10px);
}

.allhed .h3 {
  font-weight: bold;
  font-size: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.allboxhed {
  color: white;
  margin-top: 20px;
  padding: 0 30px;
}

.boxmain {
  padding: 0 30px;
  box-sizing: border-box;
  margin: 30px;
  min-height: 150px;
}

.boxmain .san {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 30px solid #292626;
  border-left: 30px solid transparent;
}

.allstrip {
  height: 20px;

  background-color: #85a34c;
}

.allstripbox {
  width: 80%;
  margin: 15px auto;
  height: 20px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.allstripbox span {
  position: absolute;
  display: inline-block;
  width: 100%;
  top: 50%;
  left: 50%;
  font-weight: bold;
  transform: translate(-50%, -50%);
  color: black;
}

.boxmain .facilityBox {
  flex-basis: 100%;
  display: inline-block; /* 在同一行显示 */
  vertical-align: top; /* 顶部对齐，可以根据需要调整对齐方式 */
  width: 180px;
  //height: 150px;
  background-color: #1d843e;
  margin-right: 50px;
  margin-bottom: 30px;
  text-align: center;
  cursor: pointer;
  color: white;
  font-size: 14px;
  position: relative;
}

.boxmain .facilityBox p {
  margin: 0;
}

.boxmain .off {
  background-color: #999ca5;
}

.boxmain .facilityBox p:nth-child(1) {
  word-wrap: break-word;
  line-height: 30px;
  padding: 15px 0 ;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.allboxhed span {
  padding: 0 5px;
  line-height: 28px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 10px;
  font-size: 20px;
}

.allboxhed .section {
  background-color: #06acfa;
  font-size: 18px;
  font-weight: bold;
}

.allboxhed .onlin {
}

.status .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 30px;
}

.statusSpan {
  margin-left: 30px;
  background-color: #35b72a;
  padding: 0px 20px;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  float: right;
}

.facilityBox {
  width: 150px;
  height: 150px;
  background-color: #35b72a;
  display: inline-block;
  margin-right: 50px;
  margin-bottom: 30px;
  text-align: center;
  cursor: pointer;
}

.status .off {
  background-color: #c8c8c8;
}

.facilityBox p:nth-child(1) {
  line-height: 60px;
  font-size: 18px;
  margin: 0;
}

.facilityBox p:nth-child(2) {
  line-height: 20px;
  font-size: 12px;
  margin: 0;
}

.facilityBox p:nth-child(3) {
  line-height: 30px;
  font-size: 14px;
  margin: 0;
}

.facilityBox p:nth-child(4) {
  line-height: 20px;
  font-size: 12px;
  margin: 0;
  margin-bottom: 10px;
}

.charttext {
  width: 300px;
  display: inline-block;
  text-align: center;
}

.chartbox {
  height: 100px;
  width: 300px;
}

.box-card {
  border: 0;
  margin-bottom: 24px;

  .el-card__header {
    padding-left: 12px;
    padding-right: 12px;
  }

  .el-card__body {
    padding: 12px;
  }

  .num {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .el-divider--horizontal {
    margin: 8px 0;
  }

  .bot {
    font-size: 14px;
  }

  .traffic-title {
    margin: 0;
    margin-bottom: 10px;
  }

  .traffic-li {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    margin-top: 10px;
  }

  .traffic-num {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-right: 8px;

    &.hot {
      background-color: rgba($color: #209e91, $alpha: 0.8);
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1600px) {
  .username {
    font-size: 24px;
  }
  .username img {
    height: 40px !important;
  }
  .allhed .h3 {
    font-size: 36px;
  }
}

.facilityBox {
  position: relative;
}

.tj {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ff9f0e;
  top: 0;
  left: 0;
}

@media screen and (max-width: 750px) {
  .all {
    width: 100%;
    min-width: 100%;
    height: 100%;
    overflow-y: scroll;
    position: fixed;
    z-index: 999;
    background-color: #292626;
    top: 0;
    left: 0;
    padding: 0 0 50px 0;
    box-sizing: border-box;
    padding-bottom: 60px !important;
  }
  .allhed {
    width: 100%;
    text-align: center;
    color: white;
    height: 100px;
    line-height: 50px;
    height: 50px;
    border-bottom: 3px solid white;
    padding: 0 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;

    .el-button {
      width: 90vw;
      height: 30px;
      position: fixed;
      top: initial;
      bottom: 15px;
      z-index: 99;
      left: 5vw;
    }
  }
  .allhed .h3 {
    display: none;
  }
  .allhed span:nth-child(3) {
    display: none;
  }
  .username {
    font-size: 20px;
    width: 100%;
    font-weight: bold;
  }
  .username img {
    height: 30px !important;
    width: auto !important;
    display: none;
  }
  .allboxhed {
    color: white;
    margin-top: 10px;
    padding: 0 10px;
  }
  .allboxhed span {
    padding: 0 5px;
    line-height: 24px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
    font-size: 14px !important;
    margin-top: 5px;
  }
  .boxmain {
    padding: 0 0px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10px;
    margin-bottom: 0px;
    overflow: hidden;
  }
  .boxmain .facilityBox {
    width: 47%;
    height: 220px;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 0px;
    text-align: center;
    cursor: pointer;
    margin-right: 0px;
    color: white;
    font-size: 14px;
    position: relative;
    float: left;
  }
  .jgbox {
    font-size: 12px;
  }
  .boxmain .facilityBox:nth-child(2n + 2) {
    float: right;
  }
  .boxmain .facilityBox p:nth-child(1) {
    // line-height: 40px;
    // font-size: 22px;
    // font-weight: bold;
    // margin: 0;
  }
  .boxmain .san {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 20px solid #292626;
    border-left: 20px solid transparent;
  }
  .statusSpan {
    margin-left: 0px;
    background-color: #35b72a;
    padding: 0px 10px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    float: initial;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  .main-conent-minheight {
    min-height: 100% !important;
    margin: 10px;
  }
  .main-conent {
    margin: 10px;
  }
  .facilityBox {
    width: 42vw;
    height: 40vw;
    background-color: #35b72a;
    display: inline-block;
    margin-right: 0px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
  }

  .indexlayout-right-footer[data-v-ad21c7f0] {
    padding: 0px 0 0px;
    height: 30px;
    overflow: hidden;
    text-align: center;
    font-size: 14px;
    color: #808695;
  }
  .el-message-box {
    width: 80% !important;
    top: 50%;
  }
  .boxmain .facilityBox p:nth-child(1) {
    line-height: 40px;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  .facilityBox p:nth-child(2) {
    line-height: 16px;
    font-size: 12px;
    margin: 0;
  }
  .facilityBox p:nth-child(3) {
    line-height: 24px;
    font-size: 12px;
    margin: 0;

    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }
  .facilityBox p:nth-child(4) {
    line-height: 16px;
    font-size: 12px;
    margin: 0;
    margin-bottom: 10px;
  }
  .facilityBox p:nth-child(5) {
    line-height: 14px;
    font-size: 12px;
    margin: 0;
    margin-bottom: 10px;
  }
  .allboxhed {
    margin-bottom: 20px;
  }
}

.boxSize1 {
  .facilityBox {
    width: 150px;
    height: auto;

    display: inline-block;
    margin-right: 30px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
    color: white;
    font-size: 12px;
    position: relative;

    p:nth-child(3) {
      line-height: 20px;
      font-size: 12px;
      margin: 0;
    }

    p:nth-child(1) {
      line-height: 50px;
      font-size: 22px;
      font-weight: bold;
      margin: 0;
    }
  }
}

.boxSize2 {
  .facilityBox {
    width: 140px;
    height: auto;

    display: inline-block;
    margin-right: 25px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    color: white;
    font-size: 11px;
    position: relative;

    p:nth-child(3) {
      line-height: 20px;
      font-size: 12px;
      margin: 0;
    }

    p:nth-child(1) {
      line-height: 40px;
      font-size: 20px;
      font-weight: bold;
      margin: 0;
    }
  }
}

.boxSize3 {
  .facilityBox {
    width: 130px;
    height: auto;

    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    color: white;
    font-size: 10px;
    position: relative;

    p:nth-child(3) {
      line-height: 15px;
      font-size: 10px;
      margin: 0;
    }

    p:nth-child(1) {
      line-height: 36px;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }
  }
}

.boxSize4 {
  .facilityBox {
    width: 120px;
    height: auto;

    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    color: white;
    font-size: 9px;
    position: relative;

    p:nth-child(3) {
      line-height: 15px;
      font-size: 10px;
      margin: 0;
    }

    p:nth-child(1) {
      line-height: 32px;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
  }
}

.boxSize5 {
  .facilityBox {
    width: 110px;
    height: auto;

    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    color: white;
    font-size: 8px;
    position: relative;

    p:nth-child(3) {
      line-height: 12px;
      font-size: 8px;
      margin: 0;
    }

    p:nth-child(1) {
      line-height: 28px;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
    }
  }
}

.ord1 {
  background-color: rgb(153, 156, 165) !important;
}
.ord3 {
  background-color: #e3460a !important;
}
.ljbox {
  padding-left: 10px;
  text-align: left;
}
.gxhed {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  span {
    display: inline-block;
    width: 33.3%;
  }
}
.gxmain {
  // overflow-y: scroll;
  height: 60px;

  .gx {
    margin-top: 0;
    font-size: 14px;
    span {
      display: inline-block;
      width: 33.3%;
      overflow: hidden;

      white-space: nowrap;

      text-overflow: ellipsis;

      -o-text-overflow: ellipsis;
    }
  }
}
.jdbox {
  width: 90%;
  margin: 10px auto;
}
</style>
